'use strict';

angular.module('cpformplastApp.items')
  .controller('itemPlanningController', function ($scope, Auth, Util, DataManager, Notification) {


    const PreviewModeOnlyRoles = ['management_dept']
    $scope.tableStructure = [
      {
        key: "moldOut",
        title: "MOULE SORTIE",
        width: 80,
        style: "text-align:center;padding:0;"
      },
      {
        key: "job",
        title: "# JOB",
        width: 100,
        style: "text-align:center;"
      },
      {
        key: "mold",
        title: "# MOULE",
        width: 100,
        style: "text-align:center;"
      },
      {
        key: "machine",
        title: "MACH.",
        width: 60,
        style: "text-align:center;"
      },
      {
        key: "client",
        title: "CLIENT",
        width: 300,
        style: ""
      },
      {
        key: "item",
        title: "MOULE",
        width: 300,
        style: ""
      },
      {
        key: "ups",
        title: "UPS",
        width: 60,
        style: "text-align:center;"
      },
      {
        key: "recycleMold",
        title: "REPRISE OR NEW",
        width: 100,
        style: "text-align:center; padding: 0;"
      },
      {
        key: "requiredDate",
        title: "DATE REQUISE",
        width: 120,
        style: "border-right: 1px solid #ddd; border-left: 1px solid #ddd; text-align:center;"
      },
      {
        key: "dieVerified",
        title: "DIE VÉRIFIÉ",
        width: 70,
        style: "text-align:center; padding:0;"
      },
      {
        key: "mMachine",
        title: "M MACHI.",
        width: 70,
        style: "border-right: 1px solid #ddd; border-left: 1px solid #ddd; text-align:center; padding:0;"
      },
      {
        key: "fFinition",
        title: "F Finition",
        width: 60,
        style: "border-right: 1px solid #ddd; text-align: center; padding:0;"
      },
      {
        key: "alumBlockOrdered",
        title: "BLOCK Alum. Commandé",
        width: 100,
        style: "border-right: 1px solid #ddd; text-align: center; padding: 0;"
      },
      {
        key: "comment",
        title: "Commentaires",
        width: 250,
        style: ""
      },
      {
        key: "dieOrderStock",
        title: "STATUT DIE",
        width: 80,
        style: "text-align:center; padding:0;"
      },
      {
        key: "readyProd",
        title: "PRÊT PROD.",
        width: 80,
        style: "text-align:center; padding:0;"
      },
      {
        key: "sheetVerification",
        title: "FEUILLE VÉRIFICATION",
        width: 130,
        style: "padding: 0;"
      },
      {
        key: "salesman",
        title: "VENDEUR",
        width: 130,
        style: "text-align:center"
      },
      {
        key: "deliveryDate",
        title: "LIVRAISON",
        width: 120,
        style: "text-align:center"
      },
      {
        key: "readyProdDate",
        title: "DATE DE SORTIE",
        width: 140,
        style: "text-align:center"
      }
    ];
    $scope.weeks = [];
    $scope.searchString = "";
    $scope.machineType = "manual";
    $scope.isPreviewMode = false;

    $scope.setIsPreviewMode = function () {
      Auth.getCurrentUser(function (user, err) {
        $scope.isPreviewMode = PreviewModeOnlyRoles.findIndex(x => x === user.role) !== -1
      })
    }
    // UI INTERACTIONS

    $scope.printContent = function () {
      DataManager.downloadMoldSchedulePdf($scope.machineType);
    };

    $scope.toggleMoldOut = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.moldOut;
      subjobSchedule.itemSchedule.moldOut = !subjobSchedule.itemSchedule.moldOut;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.moldOut = currentValue;
      }, "L'item doit être en production avant de sortir");
    };

    $scope.toggleRecycleMold = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.recycleMold;
      subjobSchedule.itemSchedule.recycleMold = !subjobSchedule.itemSchedule.recycleMold;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.recycleMold = currentValue;
      });
    };

    $scope.toggleDieVerification = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.dieVerified;
      subjobSchedule.itemSchedule.dieVerified = !subjobSchedule.itemSchedule.dieVerified;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.dieVerified = currentValue;
      });
    };

    $scope.toggleMMachine = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.mMachine;
      subjobSchedule.itemSchedule.mMachine = !subjobSchedule.itemSchedule.mMachine;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.mMachine = currentValue;
      });
    };

    $scope.toggleFFinition = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.fFinition;
      subjobSchedule.itemSchedule.fFinition = !subjobSchedule.itemSchedule.fFinition;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.fFinition = currentValue;
      });
    };

    $scope.toggleAlumBlockOrdered = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.alumBlockOrdered;
      subjobSchedule.itemSchedule.alumBlockOrdered = !subjobSchedule.itemSchedule.alumBlockOrdered;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.alumBlockOrdered = currentValue;
      });
    };

    $scope.saveComment = function (subjobSchedule) {
      if (subjobSchedule.itemSchedule.commentChanged) {
        $scope.updateSubjobSchedule(subjobSchedule);
      }
    };

    $scope.toggleDieStatus = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.dieOrderStock;
      let nextValue = 'ordered';
      if (currentValue === 'ordered') {
        nextValue = 'received';
      } else if (currentValue === 'received') {
        nextValue = 'inStock';
      } else if (currentValue === 'inStock') {
        nextValue = "";
      }
      subjobSchedule.itemSchedule.dieOrderStock = nextValue;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.dieOrderStock = currentValue;
      });
    }

    $scope.toggleReadyProd = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.readyProd;
      subjobSchedule.itemSchedule.readyProd = !subjobSchedule.itemSchedule.readyProd;
      if (subjobSchedule.itemSchedule.readyProd) {
        subjobSchedule.itemSchedule.readyProdDate = new Date();
      } else {
        subjobSchedule.itemSchedule.readyProdDate = null;
      }
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.readyProd = currentValue;
        subjobSchedule.itemSchedule.readyProdDate = null;
      }, "La feuille doit être vérifiée avant d'aller en production");
    };

    $scope.toggleSheetVerification = function (subjobSchedule) {
      const currentValue = subjobSchedule.itemSchedule.sheetVerified;
      subjobSchedule.itemSchedule.sheetVerified = !subjobSchedule.itemSchedule.sheetVerified;
      $scope.updateSubjobSchedule(subjobSchedule, () => {
        subjobSchedule.itemSchedule.sheetVerified = currentValue;
      }, "Puisque l'item est en production, la feuille doit être vérifiée");
    };

    //removes schedules where the startDate is set
    $scope.filterWeeks = function () {
      $scope.weeks = $scope.weeks.filter(week =>{
        week.schedules = week.schedules.filter(schedule=> schedule.itemSchedule.startDate == null);
        return week.schedules.length > 0;
      })
    };

    // NETWORKING

    $scope.updateSubjobSchedule = function (subjobSchedule, errorCallback, errorMSG) {
      subjobSchedule.itemSchedule.commentChanged = false;

      return DataManager.updateSubjobSchedule(subjobSchedule, 'single')
        .then(schedule => {
          subjobSchedule = schedule;
          $scope.filterWeeks();
        })
        .catch(err => {
          console.log(err);
          errorCallback();
          Notification.error(errorMSG ? errorMSG : 'Un problème est survenu');
        });
    };

    $scope.fetchSchedules = function () {
      DataManager.fetchSubjobSchedules('week', $scope.machineType, null, "mold")
        .then(weeks => {
          $scope.weeks = weeks;
          $scope.adjustPageSize();
        })
        .catch(err => {
          console.log(err);
          Notification.error('Un problème est survenu lors du chargement des cédules');
        });
    };

    // DOM Management

    $scope.adjustPageSize = function () {
      const pageHeight = $(window).height();
      const topnavHeight = $('#topnav').height();
      const breadcrumbHeight = $('.breadcrumb').height();
      const searchRowHeight = $('.search-row').height();
      const headerHeight = $('.planning-header').height();

      const weekListHeight = pageHeight - topnavHeight - breadcrumbHeight - headerHeight - 120;
      $('.week-list').height(weekListHeight);
    };

    // INIT

    $scope.init = function () {
      $scope.fetchSchedules();
      $scope.setIsPreviewMode();

      $(window).resize(function () {
        $scope.adjustPageSize();
      });
    };
    
    $scope.init();
  });
